import React from "react"
import { graphql } from "gatsby"
// import { Helmet } from 'react-helmet'

import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from '../../components/common/layout';
import SEO from '../../components/common/seo';
import BlogPostCard from "../../components/blog/blogpostcard";
import AuthorHeader from "../../components/blog/authorheader";

import "../../styles/blogpost.css"


// Page for a product walkthrough
export default function Author({data}) {
  const { author, recent } = data;  // data.mdx holds your post data

  return (
    <Layout
      // pageTitle={author.frontmatter.name}
      enableShare={true}
    >
      <SEO title={author.frontmatter.name} description={author.frontmatter.description}
        generateImage={true} path={`/blog/${author.frontmatter.slug}/`}
      />

      <div className="max-w-5xl mx-auto">
        <div className="content-body markdown-body">
          <h1 className="mb-0 text-center">{author.frontmatter.name}</h1>
          <div className="text-center mt-0">Community Guest Author</div>
        </div>

        <div className="mt-8">
          <div className="text-black flex bg-yellow-100 text-rose-500 p-4 rounded-2xl items-center">
            {author.frontmatter.authorImage ? (
              <img className="h-24 w-24 bg-yellow-200 rounded-full object-cover mr-6" src={author.frontmatter.authorImage} alt={author.frontmatter.author} />
            ) : ''}
            
            <div>
              <div className="text-lg font-medium">{author.frontmatter.description}</div>

              <div className="flex space-x-2 mt-2">
                {author.frontmatter.twitter ? (
                  <a href={author.frontmatter.twitter} target="_blank" className="underline">Twitter</a>
                ) : ''}
                {author.frontmatter.linkedin ? (
                  <a href={author.frontmatter.linkedin} target="_blank" className="underline">LinkedIn</a>
                ) : ''}
                {author.frontmatter.website ? (
                  <a href={author.frontmatter.website} target="_blank" className="underline">Website</a>
                ) : ''}
              </div>
            </div>

            
          </div>
        </div>


        {/*<div className="content-body markdown-body">
          <MDXRenderer>{author.body}</MDXRenderer>
        </div>*/}

        <div className="mt-12">
          <div id="recent" className="">
            <h2 className="text-2xl font-semibold flex items-center">Recently Published</h2>
            <div className="">
              <ul className={`mt-4 grid sm:grid-cols-3 gap-x-8 gap-y-4`}>
                {data.recent.edges.map((edge, index) => (
                  <BlogPostCard key={edge.node.frontmatter.slug} node={edge.node} mini={false}/>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    author: mdx(frontmatter: { slug: { eq: $slug }, type: {eq: "author"} }) {
      body
      frontmatter {
        name
        authorImage
        description
        twitter
        linkedin
        website
      }
    }
    recent: allMdx(
      filter: { frontmatter: {type: {eq: "post"}, author: {eq: $slug}}},
      sort: { order: DESC, fields: [frontmatter___published, frontmatter___slug] }
      limit: 12
    ) {
      totalCount
      edges {
        node {
          timeToRead
          frontmatter {
            slug
            published
            author
            title
            description
          }
        }
      }
    }
  }
`